import { defineStore } from 'pinia'

export const useGeneralStore = defineStore("general", {
    state: () => ({
        currentPageID: null,
        parentPageID: null,
        companyData: {},
        currentSubPage: '',
        loggedIn: false
    }),

    actions: {
        /**
         * 
         * @param {*} payload 
         */
        setSubPage(payload) {
            let path = payload
            let pathArr = path.split('/')
            path = pathArr[pathArr.length - 1]

            this.currentSubPage = path;
        },

        /**
         * 
         * @param {*} payload 
         */
        setParentPageID(payload) {
            this.parentPageID = payload;
        },

        /**
         * 
         */
        deleteSubPage() {
            this.currentSubPage = '';
        },

        /**
         * 
         * @param {*} processID 
         */
        setProcessID(processID) {
            this.currentPageID = processID;
        },

        /**
         * 
         * @returns this.currentArticleID
         */
        getProcessID() {
            return this.currentPageID;
        },

        /**
         *
         * @param {*} lang
         */
        async createCompanyData (lang) {
            const { data } = await useFetch(`https://administration.w-s.app/api/v1/services/base-data/${lang}`);

            if (data.value) {
                this.companyData = data.value;
            }
        },

        /**
         * 
         * @param {*} payload 
         */
        toggleLoggedIn(payload) {
            this.loggedIn = payload;
        },
    }
})